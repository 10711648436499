// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-bg-js": () => import("./../../../src/pages/about.bg.js" /* webpackChunkName: "component---src-pages-about-bg-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-art-bg-js": () => import("./../../../src/pages/art.bg.js" /* webpackChunkName: "component---src-pages-art-bg-js" */),
  "component---src-pages-art-en-js": () => import("./../../../src/pages/art.en.js" /* webpackChunkName: "component---src-pages-art-en-js" */),
  "component---src-pages-contact-bg-js": () => import("./../../../src/pages/contact.bg.js" /* webpackChunkName: "component---src-pages-contact-bg-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-index-bg-js": () => import("./../../../src/pages/index.bg.js" /* webpackChunkName: "component---src-pages-index-bg-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-products-bg-js": () => import("./../../../src/pages/products.bg.js" /* webpackChunkName: "component---src-pages-products-bg-js" */),
  "component---src-pages-products-en-js": () => import("./../../../src/pages/products.en.js" /* webpackChunkName: "component---src-pages-products-en-js" */),
  "component---src-pages-projects-bg-js": () => import("./../../../src/pages/projects.bg.js" /* webpackChunkName: "component---src-pages-projects-bg-js" */),
  "component---src-pages-projects-en-js": () => import("./../../../src/pages/projects.en.js" /* webpackChunkName: "component---src-pages-projects-en-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/projectTemplate.js" /* webpackChunkName: "component---src-templates-project-template-js" */)
}

